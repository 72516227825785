import { Switch, Route, useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from "react-transition-group";

//main
import Dashbord from '../views/backend/Main/Dashbord'
import Agents from '../views/backend/Agents/Agents';
import AddAgents from '../views/backend/Agents/AddAgents';
import TermsAndConditions from "../views/backend/ContentManagement/TermsAndConditions";
import PrivacyPolicy from "../views/backend/ContentManagement/PrivacyPolicy";
import Notification from "../views/backend/Notification/Notification";


const Layout1Route = () => {

    let location = useLocation();

    return (
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={300}
            >
                <Switch location={location}>
                    <Route path="/" exact component={Dashbord} />
                    <Route path="/agents" exact component={Agents} />
                    <Route path="/add-agent" exact component={AddAgents} />
                    <Route path="/terms-and-conditions" exact component={TermsAndConditions} />
                    <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                    <Route path="/push-notification" exact component={Notification} />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default Layout1Route