import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
// import { useDashboardDataQuery } from '../store/apis/userApi';
import { useForm } from 'react-hook-form'
import { Button, Form } from 'react-bootstrap'
// import { validation, validationText } from '../utils/app-constants'
import ThemeInput from './ThemeInput'
import {BASE_URL} from "../../../helpers/apicalls/constants/constants";


import ThemeTextArea from './ThemeTextArea'
import axios from "axios";
import {useSelector} from "react-redux";
import Swal from "sweetalert2";
import {useHistory} from "react-router-dom";

// import { useSendPushMutation } from '../store/apis/userApi'
// import { errorMsg, successMsg } from '../constants/msgs';

const Notification = () => {
    const token = useSelector((state) => state.Auth.userData.api_token);
    const history = useHistory();

    const { register, control, handleSubmit, watch, setValue, formState: { errors } } = useForm({ mode: 'onChange' })
    // const [sendPush, { isLoading }] = useSendPushMutation()
    const [isLoading, setLoading] = useState(false)

    const afterSuccess = (msg) => {
        Swal.fire({
            icon: "success",
            title: "Success...",
            text: msg,
            showConfirmButton: false,
            timer: 1500
        }).then(function () {
            history.push('/push-notification');
        });
    }

    const afterError = (msg) => {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: msg,
        });
    }
    const onSubmit = async (body) => {
        try {
            setLoading(true)
            let data = await axios.post(`${BASE_URL}/notification/send-push`, {
                title: body?.title,
                message: body?.description
            }, {
                headers: {
                    "Bearer-Token": token
                }
            })
            afterSuccess(data?.data?.message)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            afterError(e?.response?.data?.message)
        }
    }
    //api calls
    const [showData, setData] = useState([])


    return (
        <div className='pages dashboard-page'>
            <Row>
                <Col xs={12} className='mb-4'>
                    <h2 className='text-black fw-800'>Push Notification</h2>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6} className='mb-3'>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {/* EMAIL INPUT */}
                        <div>
                            <ThemeInput
                                name="title"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Title is required"
                                    }
                                }}
                                label="Title"
                                placeholder="Enter your Title"
                                type="text"
                            />
                        </div>

                        {/* TEXTAREA INPUT */}
                        <div>
                            <ThemeTextArea
                                name="description"
                                control={control}
                                errors={errors}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Description is required"
                                    },
                                }}
                                label="Description"
                                placeholder="Description..."
                                type="text"
                            />
                        </div>

                        <Button type="submit" disabled={isLoading} className='btn-solid btn-purple w-100'>{isLoading ? 'Loading...' : 'Send Push Notification'}</Button>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default Notification