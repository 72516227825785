import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAgents } from '../../../redux/Agents/AgentActions';

const Dashbord = () => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.Auth.userData.api_token);
    const agents = useSelector((state) => state.Agent.agents);
    const isLoading = useSelector((state) => state.Agent.isGetAgentLoading);

    useEffect(() => {
        getAgents();
    }, []);

    console.log(agents)

    const getAgents = () => {
        dispatch(getAllAgents(token));
    }
    return (
        <>
            <Container className="h-100">
                <Row className="h-100">
                    <Col md="2 info-box" >
                        <h4 className='text-center mt-3'>Total Agents</h4>
                        <p className='text-center m-2'><b>{agents ? agents.length : 0}</b></p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Dashbord;