import { combineReducers } from "redux";
import AuthReducer from "./auth/AuthReducer";
import Mode from "./mode";
import AgentReducer from "./Agents/AgentReducer";

const rootReducer = combineReducers({
    Auth: AuthReducer,
    Agent: AgentReducer,
    mode: Mode
});

export default rootReducer;
