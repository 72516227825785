import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Accordion, Button } from 'react-bootstrap'
import Scrollbar from 'smooth-scrollbar'
import { connect } from "react-redux";
import { getDarkMode } from '../../../../store/mode'

//img
import logo from '../../../../../src/assets/images/logo.png'
import { MdOutlinePolicy } from "react-icons/md";
import { MdOutlineHandshake } from "react-icons/md";
import { IoIosNotificationsOutline } from "react-icons/io";


function mapStateToProps(state) {
    return {
        darkMode: getDarkMode(state)
    };
}


const minisidbar = () => {
    document.body.classList.toggle('sidebar-main')
}


const SidebarStyle = (props) => {

    //location
    let location = useLocation();

    const urlParams = new URLSearchParams(window.location.search);
    const sidebar = urlParams.get('sidebar');
    var variant = '';
    if (sidebar !== null) {
        variant = '';
        switch (sidebar) {
            case "0":
                variant = 'sidebar-dark';
                break;
            case "1":
                variant = 'sidebar-light';
                break;
            default:
                variant = '';
                break;
        }
    }

    // Collapse state
    const [activeMenu, setActiveMenu] = useState(false)
    const [activesubMenu, setSubmenu] = useState(false)
    useEffect(
        () => {
            Scrollbar.init(document.querySelector('#my-scrollbar'))
        }
    )
    return (
        <>
            <div className={`iq-sidebar sidebar-default ${variant}`}>
                <div className="iq-sidebar-logo d-flex align-items-end justify-content-between">
                    <Link to="/" className="header-logo">
                        <img src={logo} className={`img-fluid rounded-normal light-logo ${props.darkMode ? 'd-none' : ''}`} alt="logo" />
                        <span>Datum</span>
                    </Link>
                    <div className="side-menu-bt-sidebar-1">
                        <svg onClick={minisidbar} xmlns="http://www.w3.org/2000/svg" className="text-light wrapper-menu" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <div className="data-scrollbar" data-scroll="1" id="my-scrollbar">
                    <nav className="iq-sidebar-menu">
                        <Accordion as="ul" id="iq-sidebar-toggle" className="side-menu" onSelect={(e) => setActiveMenu(e)}>
                            <li className={`${location.pathname === '/' ? 'active' : ''}  sidebar-layout`}>
                                <Link to="/" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Dashboard</span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/agents' ? 'active' : ''}  sidebar-layout`}>
                                <Link to="/agents" className="svg-icon ">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Agents</span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/terms-and-conditions' ? 'active' : ''}  sidebar-layout`}>
                                <Link to="/terms-and-conditions" className="svg-icon ">
                                    <i className="">
                                        <MdOutlineHandshake />
                                    </i>
                                    <span className="ml-2">Terms and Conditions</span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/privacy-policy' ? 'active' : ''}  sidebar-layout`}>
                                <Link to="/privacy-policy" className="svg-icon ">
                                    <i className="">
                                        <MdOutlinePolicy />
                                    </i>
                                    <span className="ml-2">Privacy Policy</span>
                                </Link>
                            </li>
                            {/*<li className={`${location.pathname === '/push-notification' ? 'active' : ''}  sidebar-layout`}>*/}
                            {/*    <Link to="/push-notification" className="svg-icon ">*/}
                            {/*        <i className="">*/}
                            {/*            <IoIosNotificationsOutline />*/}
                            {/*        </i>*/}
                            {/*        <span className="ml-2">Push Notification</span>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                        </Accordion>
                    </nav>
                    <div className="pt-5 pb-5"></div>
                </div>
            </div>
        </>
    )
}

// export default SidebarStyle;
export default connect(mapStateToProps)(SidebarStyle)