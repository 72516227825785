import { Switch, Route } from 'react-router-dom'
import Layout1 from '../layouts/backend/Layout1'
import BlankLayout from '../layouts/BlankLayout';
import { useSelector } from 'react-redux';

const LayoutsRoute = props => {
    const userData = useSelector((state) => state.Auth.userData);
    return (
        <Switch>
            <Route path="/auth" component={BlankLayout} />
            <Route path="/extra-pages" component={BlankLayout} />
            {userData !== null ?
                <Route path="/" component={Layout1} /> :
                <Route path="/" component={BlankLayout} />}
        </Switch>
    )
}

export default LayoutsRoute