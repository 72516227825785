import { Get, Post, Put } from "../../helpers/apicalls/apicalls";
import * as actionTypes from "./AgentTypes";

export const addAgent = (data, token, afterSuccess, afterError) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_ADD_AGENT_LOADING });
        Post("/add-agent", data, token)
            .then((res) => {
                if (res.status) {
                    afterSuccess(res.message);
                    dispatch({ type: actionTypes.ADD_AGENT_SUCCESS });
                } else {
                    afterError(res.message);
                    dispatch({ type: actionTypes.ADD_AGENT_FAILED });
                }
            })
            .catch((err) => {
                afterError(err.response.data.error);
                dispatch({ type: actionTypes.ADD_AGENT_FAILED });
            })
    }
}

export const getAllAgents = (token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_AGENT_DATA_LOADING });
        Get('/get-agents', token)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: actionTypes.AGENT_DATA_SUCCESS,
                        payload: res.agents
                    });
                } else {
                    dispatch({ type: actionTypes.AGENT_DATA_FAILED });
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.AGENT_DATA_FAILED });
            })
    }
}

export const changeStatus = (data, token, afterSuccess) => {
    return (dispatch) => {
        Put('/update-agent-status', data, token)
            .then((res) => {
                if (res.status) {
                    afterSuccess(res.message);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }
}