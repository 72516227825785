import { Switch, Route } from 'react-router-dom'

//auth pages
import SignIn from '../views/backend/Auth/SignIn'

const ExtraPages = () => {
    return (
        <Switch>
            {/* auth */}
            <Route path="/auth/sign-in" component={SignIn} />
            <Route path="/" component={SignIn} />
        </Switch>
    )
}

export default ExtraPages