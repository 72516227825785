import React, { useState } from 'react';
import { Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { addAgent } from '../../../redux/Agents/AgentActions';
import Swal from 'sweetalert2';

const AddAgents = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const token = useSelector((state) => state.Auth.userData.api_token);
    const isLoading = useSelector((state) => state.Agent.isAddAgentLoading);
    const [profileImage, setProfileImage] = useState('');
    const [file, setFile] = useState(null);

    const fileChangeHandler = (e) => {
        const file = e.target.files[0]; // Get the first file selected by the user
        const reader = new FileReader();

        reader.onload = (event) => {
            const url = event.target.result; // The data URL representing the file
            setProfileImage(url);
            setFile(file);
        };

        // Read the file as a data URL
        reader.readAsDataURL(file);
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = new FormData(form);
        console.log(file, "check it")
        formData.append("file", file);
        // return
        dispatch(addAgent(formData, token, afterSuccess, afterError));
    }

    const afterSuccess = (msg) => {
        Swal.fire({
            icon: "success",
            title: "Success...",
            text: msg,
            showConfirmButton: false,
            timer: 1500
        }).then(function () {
            history.push('/agents');
        });
    }

    const afterError = (msg) => {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: msg,
        });
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/agents">Agent</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Agent</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/agents" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold0 d-flex align-items-center">Add New Agent</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Form className="g-3 date-icon-set-modal" onSubmit={(e) => onSubmitHandler(e)}>
                                    <Row>
                                        <Col md="3" className="mb-3">
                                            <Card.Body className="rounded profileUpload">
                                                {profileImage !== "" && profileImage !== null && profileImage !== undefined ?
                                                    <img src={profileImage} alt="Uploaded" style={{ maxWidth: '100%' }} />
                                                    :
                                                    <Form.Label htmlFor="profileImage" className='bg-light'>
                                                        <div className="text-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="80px" x="0px" y="0px" viewBox="0 0 419.2 419.2" style={{ enableBackground: "new 0 0 419.2 419.2" }} stroke="currentColor">
                                                                <g>
                                                                    <g>
                                                                        <g>
                                                                            <circle cx="158" cy="144.4" r="28.8" />
                                                                            <path d="M394.4,250.4c-13.6-12.8-30.8-21.2-49.6-23.6V80.4c0-15.6-6.4-29.6-16.4-40C318,30,304,24,288.4,24h-232     c-15.6,0-29.6,6.4-40,16.4C6,50.8,0,64.8,0,80.4v184.4V282v37.2c0,15.6,6.4,29.6,16.4,40c10.4,10.4,24.4,16.4,40,16.4h224.4     c14.8,12,33.2,19.6,53.6,19.6c23.6,0,44.8-9.6,60-24.8c15.2-15.2,24.8-36.4,24.8-60C419.2,286.8,409.6,265.6,394.4,250.4z      M21.2,80.4c0-9.6,4-18.4,10.4-24.4c6.4-6.4,15.2-10.4,24.8-10.4h232c9.6,0,18.4,4,24.8,10.4c6.4,6.4,10.4,15.2,10.4,24.8v124.8     l-59.2-59.2c-4-4-10.8-4.4-15.2,0L160,236l-60.4-60.8c-4-4-10.8-4.4-15.2,0l-63.2,64V80.4z M56,355.2v-0.8     c-9.6,0-18.4-4-24.8-10.4c-6-6.4-10-15.2-10-24.8V282v-12.4L92,198.4l60.4,60.4c4,4,10.8,4,15.2,0l89.2-89.6l58.4,58.8     c-1.2,0.4-2.4,0.8-3.6,1.2c-1.6,0.4-3.2,0.8-5.2,1.6c-1.6,0.4-3.2,1.2-4.8,1.6c-1.2,0.4-2,0.8-3.2,1.6c-1.6,0.8-2.8,1.2-4,2     c-2,1.2-4,2.4-6,3.6c-1.2,0.8-2,1.2-3.2,2c-0.8,0.4-1.2,0.8-2,1.2c-3.6,2.4-6.8,5.2-9.6,8.4c-15.2,15.2-24.8,36.4-24.8,60     c0,6,0.8,11.6,2,17.6c0.4,1.6,0.8,2.8,1.2,4.4c1.2,4,2.4,8,4,12v0.4c1.6,3.2,3.2,6.8,5.2,9.6H56z M378.8,355.2     c-11.6,11.6-27.2,18.4-44.8,18.4c-16.8,0-32.4-6.8-43.6-17.6c-1.6-1.6-3.2-3.6-4.8-5.2c-1.2-1.2-2.4-2.8-3.6-4     c-1.6-2-2.8-4.4-4-6.8c-0.8-1.6-1.6-2.8-2.4-4.4c-0.8-2-1.6-4.4-2-6.8c-0.4-1.6-1.2-3.6-1.6-5.2c-0.8-4-1.2-8.4-1.2-12.8     c0-17.6,7.2-33.2,18.4-44.8c11.2-11.6,27.2-18.4,44.8-18.4s33.2,7.2,44.8,18.4c11.6,11.6,18.4,27.2,18.4,44.8     C397.2,328,390,343.6,378.8,355.2z" />
                                                                            <path d="M341.6,267.6c-0.8-0.8-2-1.6-3.6-2.4c-1.2-0.4-2.4-0.8-3.6-0.8c-0.4,0-0.4,0-0.4,0c-0.4,0-0.4,0-0.4,0     c-1.2,0-2.4,0.4-3.6,0.8c-1.2,0.4-2.4,1.2-3.6,2.4l-24.8,24.8c-4,4-4,10.8,0,15.2c4,4,10.8,4,15.2,0l6.4-6.4v44     c0,6,4.8,10.8,10.8,10.8s10.8-4.8,10.8-10.8v-44l6.4,6.4c4,4,10.8,4,15.2,0c4-4,4-10.8,0-15.2L341.6,267.6z" />
                                                                        </g>
                                                                    </g>
                                                                </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                                                            </svg>
                                                            <p className="mb-0 text-muted font-weight-bold">Upload Image</p>
                                                        </div>
                                                        <input type="file" id="profileImage" name="profileImage" onChange={(e) => fileChangeHandler(e)} className='d-none' />
                                                    </Form.Label>
                                                }
                                            </Card.Body>
                                        </Col>
                                        <Col md="9">
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <Form.Label htmlFor="first_name" className="font-weight-bold text-muted text-uppercase">First Name</Form.Label>
                                                    <Form.Control type="text" id="first_name" name="first_name" placeholder="Enter First Name" required />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Label htmlFor="last_name" className="font-weight-bold text-muted text-uppercase">Last Name</Form.Label>
                                                    <Form.Control type="text" id="last_name" name="last_name" placeholder="Enter Last Name" required />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Label htmlFor="email" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                    <Form.Control type="email" id="email" name="email" placeholder="Enter Email" required />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Label htmlFor="phone" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                    <Form.Control type="phone" id="phone" name="phone" placeholder="Enter Phone" required />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Label htmlFor="password" className="font-weight-bold text-muted text-uppercase">Password</Form.Label>
                                                    <Form.Control type="password" id="password" name="password" placeholder="Enter Password" required />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end mt-3">
                                                <Button variant="btn btn-primary" type="submit">
                                                    {isLoading ? <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner> : 'Add Agent'}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AddAgents;
