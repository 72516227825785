import React, { useEffect } from 'react';
import { Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { changeStatus, getAllAgents } from '../../../redux/Agents/AgentActions';
import Swal from 'sweetalert2';

const Agents = () => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.Auth.userData.api_token);
    const agents = useSelector((state) => state.Agent.agents);
    const isLoading = useSelector((state) => state.Agent.isGetAgentLoading);

    useEffect(() => {
        getAgents();
    }, []);

    const getAgents = () => {
        dispatch(getAllAgents(token));
    }

    const afterSuccess = (msg) => {
        Swal.fire(msg, "", "success")
            .then(() => {
                getAgents();
            });
    }

    const onStatusChangeHandler = (e, userId) => {
        let status = 0;
        if (e.target.checked) {
            status = 1;
        } else {
            status = 0;
        }
        Swal.fire({
            title: "Do you want to change the status?",
            showDenyButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed) {
                const data = {
                    user_id: userId,
                    status: status
                }
                dispatch(changeStatus(data, token, afterSuccess));
            }
        });
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">Agents</h4>
                            </div>
                            <div className="create-workform">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="modal-product-search d-flex">
                                        <Link to="/add-agent" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                            Add Agent
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Row>
                            <Col lg="12">
                                <Card className="card-block card-stretch">
                                    <Card.Body className="p-0">
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <h5 className="font-weight-bold">Agent List</h5>
                                        </div>
                                        <div className="table-responsive">
                                            {isLoading ?
                                                <div className="text-center mt-5 mb-5">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div> :
                                                <table className="table data-table mb-0">
                                                    <thead className="table-color-heading">
                                                        <tr className="">
                                                            <th>Sr. No</th>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Phone</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {agents?.length > 0 ? agents?.map((agent, index) => {
                                                            return (
                                                                <tr className="white-space-no-wrap" key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td className="">
                                                                        <span className="font-weight-bold">{agent.full_name}</span>
                                                                    </td>
                                                                    <td>{agent.email}</td>
                                                                    <td>{agent.phone}</td>
                                                                    <td>
                                                                        <div className="d-flex justify-content-start align-items-center">
                                                                            <div className="custom-control custom-switch custom-switch-text custom-switch-color custom-control-inline">
                                                                                <div className="custom-switch-inner status">
                                                                                    <Form.Check.Input bsPrefix="custom-control-input bg-success status-button" id={"customSwitch-22" + index} checked={agent.status} onChange={(e) => onStatusChangeHandler(e, agent._id)} />
                                                                                    <Form.Check.Label bsPrefix="custom-control-label" htmlFor={"customSwitch-22" + index} data-on-label="Active" data-off-label="Deactive"></Form.Check.Label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) : null}
                                                    </tbody>
                                                </table>
                                            }
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        </>
    );
}

export default Agents;