import React from 'react'
import ExtraPages from '../router/extra-pages-route'


const BlankLayout = () => {

    return (
        <>
            <div className="wrapper">
                <ExtraPages />
            </div>
        </>
    )
}

export default BlankLayout;