import { Post } from '../../helpers/apicalls/apicalls';
import * as actionTypes from './AuthTypes';

export const onlogin = (data, afterSuccess, afterError) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_LOGIN_LOADING });
        Post('/auth/login', data)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: actionTypes.LOGIN_SUCCESS,
                        payload: res.user
                    });
                } else {
                    dispatch({ type: actionTypes.LOGIN_FAILED });
                    afterError(res.message)
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.LOGIN_FAILED });
                console.log(err);
            })
    }
}

export const onLogout = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.LOGOUT });
    }
}