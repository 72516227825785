import React, { useState } from 'react'
import { Container, Col, Row, Button, Form, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import { connect, useDispatch, useSelector } from "react-redux";
import { getDarkMode } from '../../../store/mode'
import { useHistory } from 'react-router-dom'
//img
import logo from '../../../assets/images/logo.png'
import darklogo from '../../../assets/images/logo-dark.png'
import { onlogin } from '../../../redux/auth/AuthActions';


function mapStateToProps(state) {
   return {
      darkMode: getDarkMode(state)
   };
}

const SignIn = (props) => {
   let history = useHistory();
   const dispatch = useDispatch();
   const isLoginLoading = useSelector((state) => state.Auth.isLoading);
   const [errorMsg, setErrorMsg] = useState('');
   const [showError, setShowError] = useState(false);

   const loginHandler = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const form = e.currentTarget;
      const formData = new FormData(form);
      dispatch(onlogin(formData, afterSuccess, afterError));
   }

   const afterSuccess = (msg) => {
      history.push('/agents');
   }

   const afterError = (msg) => {
      setShowError(true);
      setErrorMsg(msg);
   }

   return (
      <>
         <section className="login-content">
            <Container className="h-100">
               <Row className="align-items-center justify-content-center h-100">
                  <Col md="5">
                     <Card className="p-3">
                        <Card.Body>
                           <div className="auth-logo">
                              <img src={logo} className={`img-fluid  rounded-normal  ${!props.darkMode ? 'd-none' : ''}`} alt="logo" />
                              <img src={darklogo} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo" />
                           </div>
                           <h3 className="mb-3 font-weight-bold text-center">Sign In</h3>
                           <p className="text-center text-secondary mb-4">Log in to your account to continue</p>
                           <Form onSubmit={(e) => loginHandler(e)}>
                              <Row>
                                 <Col lg="12">
                                    <Form.Group>
                                       <Form.Label className="text-secondary">Email</Form.Label>
                                       <Form.Control type="email" name="email" placeholder="Enter Email" required />
                                    </Form.Group>
                                 </Col>
                                 <Col lg="12" className="mt-2">
                                    <Form.Group>
                                       <div className="d-flex justify-content-between align-items-center">
                                          <Form.Label className="text-secondary">Password</Form.Label>
                                       </div>
                                       <Form.Control type="password" name="password" placeholder="Enter Password" required />
                                    </Form.Group>
                                 </Col>
                              </Row>
                              {isLoginLoading ?
                                 <></> :
                                 <Button type="submit" variant="btn btn-primary btn-block mt-2">Log In</Button>}
                           </Form>
                        </Card.Body>
                        {errorMsg !== null && errorMsg !== "" && errorMsg !== undefined ?
                           <Alert variant="danger" show={showError}>
                              <div className="iq-alert-text">{errorMsg}</div>
                              <button type="button" className="close" onClick={() => setShowError(false)}>
                                 <i className="ri-close-line" style={{ color: '#7f1620' }}></i>
                              </button>
                           </Alert>
                           : null}
                     </Card>
                  </Col>
               </Row>
            </Container>
         </section>
      </>
   )
}

export default connect(mapStateToProps)(SignIn)