import * as actionTypes from "./AgentTypes";

const INTIAL_STATE = {
    agents: null,
    isAddAgentLoading: false,
    isGetAgentLoading: false,
};

const AgentReducer = (state = INTIAL_STATE, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.IS_AGENT_DATA_LOADING:
            return {
                ...state,
                isGetAgentLoading: true
            };
        case actionTypes.AGENT_DATA_SUCCESS:
            return {
                ...state,
                isGetAgentLoading: false,
                agents: payload
            };
        case actionTypes.AGENT_DATA_FAILED:
            return {
                ...state,
                isGetAgentLoading: false,
                agents: payload
            };
        case actionTypes.IS_ADD_AGENT_LOADING:
            return {
                ...state,
                isAddAgentLoading: true
            };
        case actionTypes.ADD_AGENT_SUCCESS:
            return {
                ...state,
                isAddAgentLoading: false
            };
        case actionTypes.ADD_AGENT_FAILED:
            return {
                ...state,
                isAddAgentLoading: false
            };

        default:
            return state;
    }
};

export default AgentReducer;
