import * as actionTypes from "./AuthTypes";

const INTIAL_STATE = {
    userData: null,
    isLoginLoading: false
};

const AuthReducer = (state = INTIAL_STATE, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.IS_LOGIN_LOADING:
            return {
                ...state,
                isLoginLoading: true
            };
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isLoginLoading: false,
                userData: payload
            };
        case actionTypes.LOGIN_FAILED:
            return {
                ...state,
                isLoginLoading: false
            };
        case actionTypes.LOGOUT:
            return {
                ...state,
                userData: null
            };

        default:
            return state;
    }
};

export default AuthReducer;
