
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import {useHistory, useParams} from 'react-router-dom'
import ThemeTextEditor from './ThemeTextEditor.js'
import { useForm } from 'react-hook-form'
import axios from "axios";
import {BASE_URL} from "../../../helpers/apicalls/constants/constants";
import {useSelector} from "react-redux";
import Swal from "sweetalert2";
// import { useLazyGetPageQuery, useUpdatePageMutation } from '../store/apis/contentApi'
// import ThemeSpinner from '../components/general/ThemeSpinner'
// import { successMsg } from '../constants/msgs'

const PrivacyPolicy = () => {
    const token = useSelector((state) => state.Auth.userData.api_token);

    const history = useHistory()
    const { register, control, handleSubmit, watch, setValue, formState: { errors } } = useForm({ mode: 'onChange' })
    const { slug } = useParams()
    const [onUpdate, setOnUpdate] = useState(1)
    // const [getPage, { isLoading: isPageLoading }] = useLazyGetPageQuery()
    // const [updatePage, { isLoading }] = useUpdatePageMutation()
    const [idContent, setIdContent] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const afterSuccess = (msg) => {
        Swal.fire({
            icon: "success",
            title: "Success...",
            text: msg,
            showConfirmButton: false,
            timer: 1500
        }).then(function () {
            history.push('/privacy-policy');
        });
    }

    const afterError = (msg) => {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: msg,
        });
    }

    const onSubmit = async (formData) => {
        try {
            setLoading(true)
            let data = await axios.put(`${BASE_URL}/page/${idContent}`, {
                content: formData?.editor
            }, {
                headers: {
                    "Bearer-Token": token
                }
            })

            afterSuccess("Privacy Policy content updated successfully")
            setOnUpdate(onUpdate + 1)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            afterError(e?.response?.data?.message)
            console.log(e?.message)
        }
    }

    useEffect(() => {
        const getPageDetail = async () => {
            try {
                if(token) {
                    const page = await axios.get(`${BASE_URL}/page?slug=privacy_policy`,{
                        headers: {
                            "Bearer-Token": token
                        }
                    })
                    setIdContent( page?.data?.data[0]?._id)
                    setValue("editor", page?.data?.data[0]?.content)
                }

            } catch (e) {
                console.log(e?.message)
            }
        }

        getPageDetail()
    }, [slug, onUpdate])

    return (
        <div className='pages content-management-page'>
            <Row>
                <Col xs={12} className='mb-3'>
                    <h2 className='text-black text-capitalize fw-800'>Privacy Policy</h2>
                </Col>

                <Col xs={12}>
                    <div className="wrapper">
                        <Row>
                            <Col xl={12}>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <ThemeTextEditor
                                        name="editor"
                                        control={control}
                                        errors={errors}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Required"
                                            }
                                        }}
                                        placeholder="Start typings..."
                                    />
                                    <Button type='submit' disabled={isLoading} className='mt-2 btn-solid btn-purple'>{isLoading ? "Loading..." : "Save"}</Button>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default PrivacyPolicy